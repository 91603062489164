import React, { useEffect, useState } from 'react';
import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Commands from "./components/Commands";
import AdhanShare from "./components/AdhanShare";
import Azkar from "./components/Azkar";

import { Route, Routes } from "react-router-dom";
import Radio from "./components/Radio";
import Hadiths from "./components/Hadiths";
import Tos from "./components/Tos";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { ScrollToTopProvider } from "./components/ScrollToTopContext";
import Article from './components/Article';
import Articles from './components/Articles';
import Redirect from './components/Redirect';

function App() {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <div className="App bg-dark bg-image min-h-screen rtl">
            <ScrollToTopProvider>
              <Navbar />
              <Hero />
              <Features />
              <Footer />
              </ScrollToTopProvider>
            </div>
          </>
        }
      />
      <Route
        path="/commands"
        element={
          <>
            <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
            <ScrollToTopProvider>
              <Navbar />
              <Commands />
              <Footer />
              </ScrollToTopProvider>
            </div>
          </>
        }
      />
      <Route
        path="/adhan/:code"
        element={
          <>
            <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
            <ScrollToTopProvider>
              <Navbar />
              <AdhanShare />
              <Footer />
              </ScrollToTopProvider>
            </div>
          </>
        }
      />
      <Route
      path="/radio/:code"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Radio />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/radio/"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Radio />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/azkar"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Azkar />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/azkar/:tabId"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Azkar />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/hadiths"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Hadiths />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/hadith/:id"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Hadiths />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/articles"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Articles />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/article/:id"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Article />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/tos"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <Tos />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
      <Route
      path="/privacy"
      element={
        <>
          <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
          <ScrollToTopProvider>
            <Navbar />
            <PrivacyPolicy />
            <Footer />
            </ScrollToTopProvider>
          </div>
        </>
      }/>
        <Route
          path="*"
          element={
            <>
              <div className="App bg-gradient-to-b from-dark to-bg-secondary bg-image min-h-screen rtl">
              <ScrollToTopProvider>
                <Navbar />
                <Redirect />
                <Footer />
                </ScrollToTopProvider>
              </div>
            </>
          }
        />
    </Routes>
  );
}

export default App;
