import {
  faBookOpenReader,
  faCloudMoon,
  faFileVideo,
  faKaaba,
  faMosque,
  faTrophy,
  faWandMagicSparkles,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Features = () => {
  const features = [
    {
      icon: <FontAwesomeIcon icon={faCloudMoon} />,
      class: "col-span-2",
      title: "أذكار وأدعية",
      description:
        "استمتع بتصفح مجموعة مختارة من الأذكار والأدعية التي تضفي على يومك بركة وإلهامًا. اختر التوقيت والمكان، وسيتولى البوت إرسالها إلى الروم الخاص بك بطريقة مميزة مع صور تضيف لمسة جميلة إلى سيرفرك.",
    },
    {
      icon: <FontAwesomeIcon icon={faMosque} />,
      class: "col-span-1",
      title: "تنبيهات مواقيت الصلاة",
      description:
        "لا تدع صلاتك تفوتك مرة أخرى! حدد مدينتك لتلقي إشعارات دقيقة لمواقيت الصلاة مباشرة في قناتك الخاصة. يمكنك أيضًا ضبط التنبيهات لعدة مواقع يصل عددها إلى خمسة في سيرفر واحد.",
    },
    {
      icon: <FontAwesomeIcon icon={faFileVideo} />,
      class: "col-span-1",
      title: "مقاطع قرآنية قصيرة",
      description:
        "ابحث عن لحظات من السكينة والتأمل مع البوت! اطلب منه إرسال مقاطع قصيرة من القرآن الكريم إلى الروم الخاص بك على فترات زمنية محددة، لتضيف جوًا من الروحانية والهدوء إلى يومك.",
    },
    {
      icon: <FontAwesomeIcon icon={faTrophy} />,
      class: "col-span-1",
      title: "ألعاب دينية تفاعلية",
      description:
        "اختبر معرفتك الدينية بطريقة ممتعة مع ألعاب تنافسية وفعاليات مشوقة! أجب عن أسئلة في الدين الإسلامي والقرآن الكريم، وشارك في تحديات مميزة تناسب الجميع، لتتعلم وتستمتع في نفس الوقت.",
    },
    {
      icon: <FontAwesomeIcon icon={faWindowRestore} />,
      class: "col-span-1",
      title: "واجهة مستخدم سهلة وبسيطة",
      description: `تجربة مميزة بواجهة سهلة وسريعة التفاعل.
سواء كنت مبتدئًا أو خبيرًا، ستجد البوت سهل الاستخدام مع استجابة فائقة السرعة وتحديثات دورية تضيف ميزات جديدة باستمرار.`,
    },
    {
      icon: <FontAwesomeIcon icon={faKaaba} />,
      class: "col-span-1",
      title: "تفاصيل شاملة عن الحج والعمرة",
      description: `تعلم وأدِّ مناسك الحج والعمرة بسهولة وثقة.
يقدم البوت إرشادات كاملة حول المناسك خطوة بخطوة، مع معلومات تاريخية وروحية تثري تجربتك.
`,
    },
  ];
  return (
    <div
      className="min-h-screen relative flex flex-col items-center bg-dark border-b border-dark-3 mb-8 text-white p-24 max-md:p-12"
      id="features"
    >
      <div className="text-center">
        <h1 className="text-4xl text-primary font-bold">
          كل ما تحتاجه في مكان واحد!
        </h1>
        <p className="text-lg text-text-2 font-normal mt-2">
          يحتوي البوت على العديد من الميزات التي تساعدك في حياتك اليومية
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 gap-6 relative z-50">
        {/*  bento grid of features */}
        {features.map((feature, index) => (
          <div
            key={index}
            className={`relative z-50 flex flex-col items-start justify-center p-4 bg-gradient-to-bl from-dark-2 to-dark-3 shadow-md shadow-secondary-bg border border-bg-secondary hover:scale-105 transition-all rounded-lg`}
          >
            <div className="text-primary text-2xl h-12 w-12 flex justify-center items-center rounded-md bg-dark-2">
              {feature.icon}
            </div>
            <h2 className="text-xl font-bold text-start mt-4">
              {feature.title}
            </h2>
            <p className="text-secondary-100 text-start mt-2">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
