import React, { useEffect, useState } from "react";
import InviteHero from "./InviteHero";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const navigate = useNavigate();
  const redirect = (path) => {
    if (window.audio && window.audio.playing) {
      window.audio.pause();
    }
    navigate(path);
  };
  const [scrollButton, setScrollButton] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.2) {
            setScrollButton(false);
          } else {
            setScrollButton(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    const footer = document.querySelector("#invite");
    if (footer) {
      observer.observe(footer);
    }

    return () => {
      if (footer) {
        observer.unobserve(footer);
      }
    };
  }, []);

  return (
    <footer className="relative">
      <div className="absolute top-0 right-0 w-[25vw] h-[36rem] bg-primary blur-3xl rounded-full opacity-10 z-[1]"></div>
      <div className="absolute -top-64 left-0 w-[25vw] h-[36rem] bg-primary blur-3xl rounded-full opacity-10 z-[1]"></div>
      <div className="h-[250px] w-full" />
      <div className="flex flex-col items-center justify-center text-text-primary w-full m-auto">
        <button
          id="scroll-to-top"
          className={`fixed bottom-4 right-4 z-50 bg-dark-2 border border-bg-secondary backdrop-blur-md shadow-2xl shadow-black p-4 rounded-full w-12 h-12 flex items-center text-center justify-center transition-all ${
            scrollButton ? `opacity-0` : `opacity-100`
          }`}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            className={`text-primary text-xl transition-all`}
          />
        </button>
        <div className="relative  mx-8 h-0">
          <InviteHero className="absolute bottom-16 max-md:mt-4 max-md:p-4 z-50" />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 250"
          className="fill-dark-2 w-full z-0"
        >
          <path
            fillOpacity="1"
            d="M0,32L80,64C160,96,250,160,480,160C640,160,800,96,960,74.7C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
        <div className="flex flex-row max-md:flex-col mx-8 items-center justify-between text-text-primary px-24 max-md:px-4 w-full m-auto bg-dark-2 max-md:pt-48 ">
          <div className="w-full flex flex-row">
            <div className="flex flex-col text-right w-3/5 max-md:w-full">
              <div
                className="flex flex-row cursor-pointer transition-all hover:text-primary relative z-50"
                onClick={() => {
                  redirect("/");
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/logo-circle.png`}
                  alt="logo"
                  className="h-10 m-2 lg:p-0 rounded-full"
                />
                <p className="text-center text-xl font-medium mt-4">فإذكروني</p>
              </div>
              <p className="text-right text-sm text-gray-400 mt-4 relative z-50">
                هو بوت إسلامي متكامل يعمل على منصة ديسكورد. يضم هذا البوت العديد
                من الميزات الرائعة التي تجعله مفيدًا لجميع المستخدمين المهتمين
                بالدين الإسلامي.
              </p>
              <div className="flex flex-row max-lg:flex-col gap-2 mt-4 relative z-50">
                <button
                  onClick={() => redirect("/tos", {})}
                  className="text-gray-300 text-right hover:text-primary transition-all cursor-pointer relative z-50"
                >
                  الشروط والأحكام
                </button>
                <span className="text-gray-300 max-lg:hidden">•</span>
                <button
                  onClick={() => redirect("/privacy", {})}
                  className="text-gray-300 text-right hover:text-primary transition-all cursor-pointer relative z-50"
                >
                  سياسة الخصوصية
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row max-md:flex-col">
            <div className="flex flex-col justify-center w-1/2 m-0 h-full max-md:w-full">
              <p className="text-xl my-4 text-white mt-4 text-right">الصفحات</p>
              <div className="flex flex-col gap-2">
                <div className="text-right">
                  <button
                    onClick={() => {
                      redirect("/", {});
                      window.scrollTo(0, 0);
                    }}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    الرئيسية
                  </button>
                </div>
                <div className="text-right">
                  <button
                    onClick={() => redirect("/commands", {})}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    الأوامر
                  </button>
                </div>
                <div className="text-right">
                  <button
                    onClick={() => redirect("/azkar", {})}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    الأذكار
                  </button>
                </div>
                <div className="text-right">
                  <button
                    onClick={() => redirect("/hadiths", {})}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    الأحاديث
                  </button>
                </div>
                <div className="text-right">
                  <button
                    onClick={() => redirect("/radio", {})}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    الإذاعة
                  </button>
                </div>
                <div className="text-right">
                  <button
                    onClick={() => redirect("/articles", {})}
                    className="text-gray-400 hover:text-primary transition-all cursor-pointer"
                  >
                    المقالات
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-1/2 m-0 h-full max-md:w-full">
              <p className="text-xl my-4 text-white mt-4 text-right">الروابط</p>
              <div className="flex flex-col gap-2">
                <div className="text-right">
                  <a
                    href="https://discord.com/api/oauth2/authorize?client_id=728782652454469662&permissions=8&scope=bot%20applications.commands"
                    className="text-gray-400 hover:text-primary transition-all"
                  >
                    إضافة البوت
                  </a>
                </div>
                <div className="text-right">
                  <a
                    href="https://fazkrony.com/discord"
                    className="text-gray-400 hover:text-primary transition-all"
                  >
                    الدعم الفني
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-text-primary w-full m-auto bg-dark-2">
          <div className="w-11/12 h-[1.5px] m-auto my-8 bg-dark-3" />
          <p className="text-center text-sm text-gray-400 mb-4">
            جميع الحقوق محفوظة © {new Date().getFullYear()} فإذكروني
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
