import React from "react";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faSearch,faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faRegularBookmark } from "@fortawesome/free-regular-svg-icons";
import ControlBar from "./ControlBar";
import { useParams, useNavigate } from "react-router";

function Radio() {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [bookmarks, setBookmarks] = useState(() => {
    const savedBookmarks = localStorage.getItem("bookmarks");
    return savedBookmarks ? JSON.parse(savedBookmarks) : {};
  });

  const audioRef = useRef(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);
  const [showControlBar, setShowControlBar] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioElements = useRef({});
  const { code } = useParams();
  const navigate = useNavigate();
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    fetchData();
    document.addEventListener("mousedown", handleUserInteracted);
    return () => {
      document.removeEventListener("mousedown", handleUserInteracted);
    };
        // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
  }, [bookmarks]);

  useEffect(() => {
    if (code && data.length > 0 && !userInteracted) {
      const station = data.find((station) => station.id === parseInt(code));
      if (station && station !== current) {
        setShowControlBar(true);
        setCurrent(station);
      }
    }
    if (code && data.length > 0 && userInteracted) {
      const station = data.find((station) => station.id === parseInt(code));
      if (station && station !== current) {
        setCurrent(station);
        setIsPlaying(true);
        handlePlayPause(station);
        setPlayingAudio(station.value);
      }
    }
        // eslint-disable-next-line
  }, [code, data, current, userInteracted]);

  useEffect(() => {
    if (playingAudio && !current && userInteracted) {
      const station = data.find((station) => station.value === playingAudio);
      if (station) {
        setCurrent(station);
        setIsPlaying(true);
        setShowControlBar(true);
        handlePlayPause(station);
      }
    }
        // eslint-disable-next-line
  }, [playingAudio, current, data, userInteracted]);

  useEffect(() => {
    if (current && userInteracted) {
      audioRef.current.pause();

      const newAudio = new Audio(current.value);

      const handleLoadedMetadata = () => {
        if (!newAudio.paused && !isPlaying) {
          newAudio.pause();
        } else if (!newAudio.paused && isPlaying) {
          const promise = newAudio.play();
          if (promise !== undefined) {
            promise
              .then((a) => {})
              .catch((error) => {
                console.error("Error playing audio:", error);
              });
          }
          newAudio.volume = volume / 100;
        }
      };

      newAudio.addEventListener("loadedmetadata", handleLoadedMetadata);

      audioRef.current = newAudio;

      return () => {
        newAudio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        newAudio.pause();
      };
    }
  }, [current, isPlaying, volume, userInteracted]);

  useEffect(() => {
    const audio = audioElements.current[playingAudio];
    if (audio) {
      audio.volume = volume / 100;
    }
        // eslint-disable-next-line
  }, [volume]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/json/radio`);
      const data = await response.json();
      const newData = data.map(item => ({ ...item, bookmarked: bookmarks[item.id] || false }));
      setData(newData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleBookmark = (id) => {
    setBookmarks(prevBookmarks => ({
      ...prevBookmarks,
      [id]: !prevBookmarks[id]
    }));
  };

  const handlePlayPause = (station) => {
    if (!station) {
      return;
    }

    const stationValue = station.value;

    if (playingAudio === stationValue) {
      const audio = audioElements.current[stationValue];
      audio.volume = volume / 100;
      if (audio.paused) {
        setPlayingAudio(stationValue);
        setIsPlaying(true);
        setIsLoading(true);
        setShowControlBar(true);
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then((a) => {
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error playing audio:", error);
              setIsLoading(false);
            });
        }
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    } else {
      if (playingAudio) {
        const currentAudio = audioElements.current[playingAudio];
        if (!currentAudio.paused) {
          currentAudio.pause();
          setIsPlaying(false);
        }
      }

      setPlayingAudio(stationValue);
      setShowControlBar(true);
      let audio = audioElements.current[stationValue];
      if (!audio) {
        audio = new Audio(stationValue);
        audio.loadedMetadataHandled = false;
        audio.addEventListener("ended", () => {
          setPlayingAudio(null);
          setIsPlaying(false);
          setShowControlBar(false);
        });
        audio.addEventListener("loadedmetadata", () => {
          if (!audio.loadedMetadataHandled) {
            audio.loadedMetadataHandled = true;
          }
        });
        audioElements.current[stationValue] = audio;

        audio.volume = volume / 100;
      }

      setIsLoading(true);
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then((a) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            setIsLoading(false);
          });
      }

      setIsPlaying(true);
      setCurrent(station);
      navigate(`/radio/${station.id}`);
    }
  };

  const handleUserInteracted = (event) => {
    const closestId = event.target.closest("button")?.id;
    if (!closestId?.includes("audio-")) {
      setUserInteracted(true);
      document.removeEventListener("mousedown", handleUserInteracted);
    } else {
      document.removeEventListener("mousedown", handleUserInteracted);
    }
  };

  const filteredData = data
  .filter((station) => station.name.toLowerCase().includes(searchQuery.toLowerCase()))
  .sort((a, b) => {
    if (a.bookmarked === b.bookmarked) {
      return 0;
    }
    return a.bookmarked ? -1 : 1;
  });
  
useEffect(() => {
  setData((prevData) => {
    const newData = prevData.map((item) => ({
      ...item,
      bookmarked: !!bookmarks[item.id],
    }));
    const sortedData = newData.sort((a, b) => {
      if (a.bookmarked === b.bookmarked) {
        return 0;
      }
      return a.bookmarked ? -1 : 1;
    });
    return sortedData;
  });

  if(current || playingAudio) {
    const audio = audioElements.current[current?.value || playingAudio];
    if(audio) {
      audio.play();
    }
  }
      // eslint-disable-next-line
}, [bookmarks]);

  return (
    <div className="min-h-screen relative z-50 pt-24 px-12">
      <h1 className="text-primary text-center text-3xl font-medium relative z-10 pt-8">
        إذاعة القرآن الكريم
      </h1>
      <div className="flex justify-center items-center relative z-10 px-4">
        <div className="flex items-center bg-dark-2 border border-bg-secondary rounded-md px-4 py-2 my-4 w-full">
          <input
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="ابحث عن محطة..."
            className="w-full bg-transparent focus:outline-none text-white placeholder-text-gray-300"
            style={{ border: "none", height: "36px" }}
          />
          <FontAwesomeIcon icon={faSearch} className="text-white ml-2" />
        </div>
      </div>
      {data.length === 0 ? (
        <div className="text-center h-screen m-auto flex relative z-50">
          <div role="status" className="m-auto">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 justify-center gap-4 px-4 py-12 relative z-50">
          {filteredData.map((station, index) => (
            <div
              key={index}
              className="flex flex-row-reverse justify-center items-center text-right p-4 gap-2 shadow-md bg-dark-3 rounded-lg"
            >
                            <button
                onClick={() => toggleBookmark(station.id)}
                className="w-8 h-8 bg-transparent text-gray-300"
              >
                {bookmarks[station.id] ? (
                  <FontAwesomeIcon icon={faBookmark} />
                ) : (
                  <FontAwesomeIcon icon={faRegularBookmark} />
                )}
              </button>
              <h2 className="text-white text-sm font-medium text-right w-full">
                {station.name}
              </h2>
              <button
                className="rounded-full w-6 h-6 p-4 bg-dark-2 border border-bg-secondary flex items-center text-center m-auto justify-center"
                onClick={() => handlePlayPause(station)}
                id={`audio-${index}`}
                disabled={playingAudio === station.value && isLoading}
              >
                {playingAudio === station.value && isLoading ? (
                  <div className="text-center m-0 flex relative z-50">
                    <div role="status" className="m-auto">
                      <svg
                        aria-hidden="true"
                        className="inline w-10 h-10 p-3 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <>
                    {current === station && isPlaying ? (
                      <FontAwesomeIcon
                        icon={faPause}
                        id={`audio-${index}`}
                        className="text-white text-lg text-center"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlay}
                        id={`audio-${index}`}
                        className="text-white text-lg text-center"
                      />
                    )}
                  </>
                )}
              </button>
            </div>
          ))}
        </div>
      )}
      {showControlBar && (
        <ControlBar
          currentStation={current}
          audioRef={audioRef}
          setVolume={setVolume}
          volume={volume}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
          isLoading={isLoading}
          handlePlayPause={handlePlayPause}
          playingAudio={playingAudio}
        />
      )}
    </div>
  );
};

export default Radio;