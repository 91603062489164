import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import clearArText from "./ClearARText";

function Articles() {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const [articlesToShow, setArticlesToShow] = useState(15);

  const fetchArticles = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/articles`);
    const response = await data.json();
    setArticles(response.results);
      setLoading(false);
  };


  const handleSearchInputChange = (e) => {
    setSearchQuery(clearArText(e.target.value));
  };

  const filteredArticles = articles.filter((article) =>
    searchQuery ? clearArText(article.title).includes(searchQuery) : true
  );

  useEffect(() => {
    fetchArticles();
  }, []);

  const handleViewMoreClick = () => {
    if (viewMore) {
      setArticlesToShow((prev) => prev - 15);
    } else {
      setArticlesToShow((prev) => prev + 15);
    }
    if (articlesToShow >= filteredArticles.length) {
      setViewMore(false);
      setArticlesToShow(15);
    }
  };

  return (
    <div
      className={`min-h-screen relative transition-all z-50 px-8 py-24 max-md:px-2`}
    >
      <h1
        className={`text-primary text-center text-3xl font-medium relative z-10 pt-8 cursor-pointer transition-all hover:scale-110`}
        onClick={() => navigate("/articles")}
      >
        المقالات
      </h1>
      <div className={`flex flex-col gap-4 relative z-10`}>
        <div className="flex justify-center items-center relative z-10 px-8 w-full">
          <div className="flex items-center bg-dark-2 border border-bg-secondary rounded-md px-4 py-2 my-4 w-full">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="ابحث عن مقالة..."
              className="w-full bg-transparent focus:outline-none text-white placeholder-text-gray-300"
              style={{ border: "none", height: "36px" }}
            />
            <FontAwesomeIcon icon={faSearch} className="text-white ml-2" />
          </div>
        </div>
      </div>
      {loading ? (
          <div className="text-center h-screen m-auto flex relative z-50">
            <div role="status" className="m-auto">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
      <div className="flex flex-col gap-4 px-8 relative z-10 mb-32">
        {filteredArticles.slice(0, articlesToShow).map((article, index) => (
          <div
            key={index}
            className="article flex flex-row gap-4 justify-between bg-dark-3 transition-all cursor-pointer hover:scale-105 rounded-md p-4 text-right shadow-md shadow-dark"
            onClick={() => navigate(`/article/${article.code}`)}
          >
            <div className="flex flex-col gap-4">
              <h1
                className={`text-primary text-2xl font-medium transition-all`}
              >
                {article.title}
              </h1>
              <div className="flex gap-4 w-fit items-center text-right">
                <span className="text-white flex gap-2 w-fit items-center font-number">
                  <FontAwesomeIcon icon={faEye} className="text-bg-secondary" />{" "}
                  {article.views}
                </span>
                <span className="text-white flex gap-2 w-fit items-center font-number">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="text-bg-secondary"
                  />
                  {new Date(article.at).toLocaleDateString()}
                </span>
              </div>
            </div>
            {article.image && (
              <img
                src={article.image}
                alt={article.title}
                className="rounded-md w-32"
              />
            )}
          </div>
        ))}
        {/* if the all articles length is more than 15 */}
        {articles.length > 15 && (
          <button
            className="text-gray-300 bg-bg-secondary/25 m-auto p-2 rounded-lg w-fit transition-all hover:scale-105 text-2xl"
            id="view-"
            onClick={handleViewMoreClick}
          >
            {articlesToShow >= filteredArticles.length ? "عرض أقل" : "عرض المزيد"}
          </button>
        )}
      </div>
        )}
    </div>
  );
}

export default Articles;
