import React from "react";

function Tos() {
  return (
    <>
      <div className="min-h-screen text-right py-28 pb-32 px-8 relative z-50">
        <h1 className="text-3xl text-primary">شروط الخدمة لبوت فإذكروني</h1>
        <p className="text-gray-400 py-1">
          أهلاً بك في بوت فإذكروني. يرجى قراءة الشروط والأحكام التالية بعناية قبل استخدام البوت:
        </p>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">1.</span> الاستخدام المسموح به:
        </h2>
        <ul>
          <li className="text-gray-400">
            يجب استخدام البوت فقط لأغراض دينية محترمة، مثل توفير المعرفة الدينية والمناقشات الفكرية.
          </li>
          <li className="text-gray-400">
            لا يجوز استخدام البوت لنشر المحتوى الذي يُعتبر مسيئًا للدين الإسلامي أو المسلمين.
          </li>
        </ul>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">2.</span> السلوك الممنوع:
        </h2>
        <ul>
          <li className="text-gray-400">
            منع استخدام البوت لأي نشاط يمكن أن يتسبب في تشويه سمعة الإسلام أو المسلمين أو يثير الانقسامات بين المستخدمين.
          </li>
          <li className="text-gray-400">
            يمنع استغلال البوت لأي غرض غير قانوني أو غير أخلاقي، مثل الاحتيال أو التحريض على الكراهية.
          </li>
        </ul>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">3.</span> المسؤوليات:
        </h2>
        <ul>
          <li className="text-gray-400">
            نحن نسعى لتوفير خدمة آمنة وموثوقة، ولكننا لسنا مسؤولين عن أي ضرر ينجم عن استخدام البوت بطريقة غير ملائمة أو غير قانونية.
          </li>
          <li className="text-gray-400">
            نحتفظ بالحق في إيقاف حساب أو تقييد الوصول إلى البوت لأي مستخدم يخالف شروط الخدمة.
          </li>
        </ul>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">4.</span> حقوق النشر:
        </h2>
        <ul>
          <li className="text-gray-400">
            جميع الحقوق محفوظة لمنظمة بوت فإذكروني. يمنع نسخ أو تعديل أو نقل أي جزء من البوت دون إذن مسبق من الإدارة.
          </li>
          <li className="text-gray-400">
            يمنع استخدام أو توزيع أو تعديل أو نقل أي جزء من الشفرة المصدرية للبوت دون إذن صريح.
          </li>
        </ul>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">5.</span> حفاظ ملكية وأفكار البوت:
        </h2>
        <p className="text-gray-400">
          نحن نحتفظ بجميع الحقوق فيما يتعلق بتصميم البوت ووظائفه وأفكاره الأصلية. أي مساهمات أو اقتراحات يتم تقديمها من قبل المستخدمين تصبح جزءًا من ملكية المنظمة دون أي حقوق مالية أو معنوية.
        </p>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">6.</span> تغييرات الشروط:
        </h2>
        <p className="text-gray-400">
          نحتفظ بالحق في تعديل أو تحديث شروط الخدمة في أي وقت، وسيتم نشر أي تغييرات جديدة على هذه الصفحة. يجب على المستخدمين مراجعة الشروط بشكل دوري.
        </p>
        <h2 className="text-white text-xl py-2">
          <span className="font-number">7.</span> بنود عامة:
        </h2>
        <ul>
          <li className="text-gray-400">
            نحن نحتفظ بالحق في تعديل أو إلغاء أي جزء من البوت أو الخدمات المقدمة دون إشعار مسبق.
          </li>
          <li className="text-gray-400">
            نحن غير مسؤولين عن أي خسائر أو أضرار ناتجة عن استخدام البوت بطريقة غير ملائمة أو غير قانونية.
          </li>
          <li className="text-gray-400">
            يجب على المستخدمين تحمل المسؤولية الكاملة عن استخدامهم للبوت وتبعات ذلك.
          </li>
        </ul>
        <p className="text-gray-400">
          باستخدامك لهذا البوت، فإنك توافق على الالتزام بشروط الخدمة المذكورة أعلاه. في حالة عدم الموافقة عليها، يرجى عدم استخدام البوت.
        </p>
      </div>
    </>
  );
}

export default Tos;