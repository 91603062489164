import React, { useState, useEffect } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const [scroll, setScroll] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const changeBackground = () => {
    setScroll(window.scrollY >= 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const redirect = (path) => {
    if (document.location.pathname.includes(`/radio`)) {
      window.location.href = path;
    } else {
      navigate(path);
    }
    setNavbarOpen(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth > 768) {
      setNavbarOpen(false);
    }
  });

  return (
    <>
      <div
        className={
          `navbar fixed left-0 right-0 m-auto z-[999] py-4 text-text-primary w-full px-4` +
          (scroll
            ? " bg-opacity-50 backdrop-blur-[8px]"
            : " bg-opacity-0 max-md:backdrop-blur-[8px] max-md:bg-opacity-50")
        }
      >
        <div className="flex flex-row-reverse gap-4 justify-between items-center">
          <button
            onClick={() => {
              redirect("/");
              window.scrollTo(0, 0);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="logo"
              className="h-10 m-2 lg:p-0 hover:scale-110"
            />
          </button>
          <div className="flex gap-4 max-md:hidden p-2">
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={() => {
                redirect("/");
                window.scrollTo(0, 0);
              }}
            >
              الرئيسية
            </button>
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={(e) => redirect("/commands", {})}
            >
              الأوامر
            </button>
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={(e) => redirect("/azkar", {})}
            >
              الأذكار
            </button>
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={(e) => redirect("/hadiths", {})}
            >
              الأحاديث
            </button>
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={(e) => redirect("/radio", {})}
            >
              الإذاعة
            </button>
            <button
              className="hover:text-primary hover:scale-105 cursor-pointer transition-all"
              onClick={(e) => redirect("/articles", {})}
            >
              المقالات
            </button>
          </div>
          <div className="hidden max-md:block">
            <button
              className="flex justify-center items-center h-6 w-8 text-2xl"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={navbarOpen ? faTimes : faBars} />
            </button>
          </div>
        </div>
        <div
          className={`navbar-collapse ${navbarOpen ? "open" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`navbar-items`}>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={() => {
                redirect("/");
                window.scrollTo(0, 0);
              }}
            >
              الرئيسية
            </button>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={(e) => redirect("/commands", {})}
            >
              الأوامر
            </button>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={(e) => redirect("/azkar", {})}
            >
              الأذكار
            </button>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={(e) => redirect("/hadiths", {})}
            >
              الأحاديث
            </button>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={(e) => redirect("/radio", {})}
            >
              الإذاعة
            </button>
            <button
              className="hover:text-primary text-right hover:scale-105 transition-all cursor-pointer"
              onClick={(e) => redirect("/articles", {})}
            >
              المقالات
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
