import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignRight, faBookOpen, faInfoCircle, faSearch, faStar, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router";

function Hadiths() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [hadith, setHadith] = useState({});
  const [hadithLoading, setHadithLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (id) {
      fetchHadithById(id);
    }
  }, [id]);

  const fetchHadithById = (id) => {
    fetch(`https://hadeethenc.com/api/v1/hadeeths/one/?language=ar&id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          setHadith(data);
          setHadithLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = setTimeout(() => {
      if (searchQuery.trim().length >= 2) {
        setLoading(true);
        fetchSearchResults();
      }
    }, 1500);

    setTypingTimeout(timeoutId);

    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const fetchSearchResults = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/hadith_search/${searchQuery}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.results.length === 0) {
          setSearchResults([{ error: "لا يوجد نتائج" }]);
        } else {
          setSearchResults(data.results);
        }
        setLoading(false);
      })
      .catch((error) => {
        setSearchResults([{ error: "لا يوجد نتائج" }]);
        setLoading(false);
      });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleResultClick = (hadith) => {
    navigate(`/hadith/${hadith.id}`);
  };

  return (
    <div className={`min-h-screen relative transition-all z-50 ${!id && searchResults.length === 0 ? `py-64` : `py-24`} px-12 max-md:px-2`}>
                <h1 className={`text-primary text-center text-3xl font-medium relative z-10 pt-8 cursor-pointer transition-all ${searchResults.length === 0 && !hadith ? `mt-48` : ``}`}
                onClick={() => navigate("/hadiths")}>
            موسوعة الاحاديث
          </h1>
      {id ? (
        hadithLoading ? (
          <div className="text-center h-screen m-auto flex relative z-50">
            <div role="status" className="m-auto">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 relative z-10 py-8">
                <div className={`flex max-md:flex-col w-full shadow-md flex-row justify-between relative gap-4 bg-gradient-to-b from-dark-2 to-bg-secondary p-8 items-center m-auto rounded-lg text-text-primary`}>
                  <div className="flex flex-col">
                    <img src={`${process.env.PUBLIC_URL}/wave.png`} alt="wave" className="left-0 absolute top-0 w-full h-full rounded-b-lg" />
            <div className="flex flex-col gap-2 relative z-50">
            <h3 className="text-white text-right text-lg font-medium p-2">
              <span className="text-primary">الحديث: </span>
              {hadith.title}
            </h3>
              <h1 className="text-white text-right text-lg font-medium p-2">{hadith.hadith}</h1>
              <span className="text-gray-400 font-medium text-right text-[18px]">
                {`[${hadith.grade}]`}
                <span className="text-white"> - </span>
                {`[${hadith.attribution}]`}
              </span>
              </div>
      </div>
    </div>
    <span className="text-white flex gap-2 text-right text-2xl items-center w-fit p-2 font-medium">
      <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />
                الشرح
              </span>
              <p className="text-white text-right text-lg font-medium p-2">{hadith.explanation}</p>
              <div className="h-[1px] w-[75%] bg-primary/50 mt-4 relative z-10"></div>
              {hadith.words_meanings?.length > 0 && (
              <span className="text-white flex gap-2 text-right text-2xl items-center w-fit p-2 font-medium">
                <FontAwesomeIcon icon={faAlignRight} className="text-primary" />
                معاني الكلمات
              </span>
              )}
              <ul className="text-white text-right text-lg font-medium p-2">
                {hadith.words_meanings?.map((data, index) => (
                  <li key={index} className="flex-col list-disc ms-6">
                    <span className="font-medium text-primary">{data.word}</span>:
                    <span className="text-white font-light">{data.meaning}</span>
                  </li>
                ))}
              </ul>

              {hadith.hints?.length > 0 && (
              <span className="text-white flex gap-2 text-right text-2xl items-center w-fit p-2 font-medium">
                <FontAwesomeIcon icon={faStar} className="text-primary" />
                من فوائد الحديث
              </span>
              )}
              <ul className="text-white text-right text-lg font-medium p-2">
                {hadith.hints?.map((benefit, index) => (
                  <li key={index} className="flex-col list-disc ms-6">
                  <span>{benefit}</span>
                  </li>
                ))}
              </ul>

              {hadith.reference && (
              <span className="text-white flex gap-2 text-right text-2xl items-center w-fit p-2 font-medium">
                <FontAwesomeIcon icon={faBookOpen} className="text-primary" />
                المراجع
              </span>
              )}
              <ul className="text-white text-right text-lg font-medium p-2">
                {hadith.reference?.split("\n").map((reference, index) => (
                  <li key={index} className="flex-col list-disc ms-6">
                  <span>{reference}</span>
                  </li>
                ))}
              </ul>
            </div>
        )
      ) : (
        <div className={`flex flex-col ${searchResults.length === 0 ? 'justify-center items-center' : ''} gap-4 relative z-10`}>
          <div className="flex justify-center items-center relative z-10 px-12 w-full">
            <div className="flex items-center bg-dark-2 border border-bg-secondary rounded-md px-4 py-2 my-4 w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                placeholder="ابحث عن حديث..."
                className="w-full bg-transparent focus:outline-none text-white placeholder-text-gray-300"
                style={{ border: "none", height: "36px" }}
              />
              <FontAwesomeIcon icon={faSearch} className="text-white ml-2" />
            </div>
          </div>
          <div className="flex flex-col gap-4 relative z-10">
            {loading ? (
              <div className="text-center py-48 m-auto flex relative z-50">
                <div role="status" className="m-auto">
                  <svg
                    aria-hidden="true"
                    className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <>
                {searchResults.length === 1 && searchResults[0]?.error ? (
                  <div className="text-center text-white">
                    <h3>{searchResults[0].error}</h3>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    {searchResults.map((hadith, index) => (
                      <div
                        key={index}
                        onClick={() => handleResultClick(hadith)}
                        className="bg-dark-2 border border-primary flex justify-between gap-2 w-full text-right p-4 rounded-md cursor-pointer hover:bg-dark transition-all"
                      >
                        <h3 className="text-primary text-sm text-right font-medium">
                          {hadith.text && searchQuery.length > 2 ? (
                            hadith.text.split(searchQuery).map((part, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && (
                                  <span className="text-white px-1 m-1 bg-bg-secondary rounded-md" key={index}>
                                    {searchQuery}
                                  </span>
                                )}
                                {part}
                              </React.Fragment>
                            ))
                          ) : (
                            hadith.text
                          )}
                        </h3>
                        <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary" />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Hadiths;